import React, { Component } from 'react';
import '../css/footer.css'
import Social from './SocialWhite';

class Footer extends Component {
    render() {
      return (
        <div className="footerContainer">
          {/* <div className="footer" >
              <span id="footer-myname">Vishnu Kumaar Mahendran • mvishnu@umich.edu</span>
              <span id="footer-social">
                <Social/>
              </span>
          </div> */}
          <div className="footer" >
              <span id="footer-myname">mvishnu@umich.edu</span>
              <span id="footer-social">
                <Social/>
              </span>
          </div> 
        </div>
        );
    }
  }
  
export default Footer;