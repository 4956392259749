import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import back from '../../img/icons/back.svg'
// import '../../css/individualProduct.css'
import '../../css/products/autocomplete.css'

class Autocomplete extends Component {
    componentDidMount() {
      window.scrollTo(0, 0);
    }
    render() {
      document.title ="Autocomplete | Vishnu"; 
      return (
        <div class="individualProductContainer">
            <div className="sectionContainer" >
              <section className="sectionSplit" id="header-autocomplete"> 
                <div className="sectionSplit_left">
                  <Link to="/" >
                  <div className="backButton">
                    <img src={back} alt="back"/>
                    <h3>&nbsp;&nbsp;Back</h3>
                    </div>
                  </Link>
                  <h1>Autocomplete experience</h1>
                  
                  <div className="productMeta"> 
                    <p>Designed a <b>language based query builder for senior security analysts</b> so that they <b>don't abandon our product</b> and use other products to search for security threats</p>
                  </div>
                  
                  <p>
                    <a target='_blank' href='https://www.figma.com/proto/pcnzhPEyfuIZQ8c5aso6FS/Autocomplete-suggestions?node-id=1%3A46&viewport=307%2C2234%2C0.18243972957134247&scaling=min-zoom' target="_blank">View slide deck</a>
                  </p>

                  {/* <a href="https://docs.google.com/presentation/d/1Ghv1GL9ktducqi38zfikZTvGUVTmVPf7WMcGxn81hH8/edit?usp=sharing" target="_blank">
                    <div className="ctaButton">
                      <h3>View project detail slides</h3>
                    </div>
                  </a> */}

                </div>

                <div className="sectionSplit_right">
                  <div className="img-hero-autocomplete"></div>
                </div>
              </section>
            </div>

            <div className="sectionbg-alt">
              <div className="sectionContainer">
                <section className="sectionCentre">
                  <h2>Design objectives</h2>
                  <div className="designObjectives"> 
                    <div className="designObjective" >   
                    <div className="img-efficiency"></div>
                      <br/>
                      <h3>Efficiency</h3>
                      <p className="designObjectiveSubtext">Analysts wanted access to information with minimum interactions</p>
                    </div>
                    
                    <div className="designObjective" >   
                    <div className="img-discoverability"></div>
                      <br/>
                      <h3>Discoverability</h3>
                      <p className="designObjectiveSubtext">Analysts wanted access to the maximum amount information</p>
                    </div>

                    <div className="designObjective" >   
                      <div className="img-customercommit"></div>
                      <br/>
                      <h3>Customer commit</h3>
                      <p className="designObjectiveSubtext">We had to deliver the project in about 4 months</p>
                    </div>
                  </div> 
                </section>
              </div>
            </div>

            <div className="sectionbg">
              <div className="sectionContainer">
                  <section className="sectionCentreHeader">
                    <h2>Team</h2>
                    <p>I collaborated with 1 product manager, 2 front end developers and 2 back end developers</p>
                  </section>
                      

                  <section className="sectionCentreHeader">
                  <h2>Design highlights</h2>
                  </section>
                  
                  <section className="sectionSplit">
                      <div className="sectionSplit_left">
                      <p className="desginObjectiveHeader">Improving Efficiency</p>
                      <span className="designObjective_header">
                        {/* <div className="designObjective-icon-efficiency"/> */}
                        <h3>1. Keep User in Context - Sticky Search Bar</h3>
                      </span>
                      <p>The search bar was designed to stick to the top of the page as the user scrolled through the results to keep users in context of their search query.</p>
                    </div>
                    <div className="sectionSplit_right">
                    <div className="img-fixedSearch"/>
                    </div>
                  </section>
              </div>

              <div className="sectionContainer">
                <section className="sectionSplit">
                    <div className="sectionSplit_left">
                      <div className="img-datepicker"/>
                    </div>
                    <div className="sectionSplit_right">
                    <p className="desginObjectiveHeader">Improving Efficiency</p>
                    <span className="designObjective_header">
                      {/* <div className="designObjective-icon-efficiency"/> */}
                      <h3>2. Efficient Query Construction</h3>
                    </span>
                    <p>Clicking a date is quicker and less erroneous than typing the queries involving date format like the following
  e.g. host.last_seen&lt;: [2019-01-02T0000 TO 2019-01-03T0000]</p>
                    </div>
                  </section>
              </div>

              <div className="sectionContainer">
                <section className="sectionSplit">
                    <div className="sectionSplit_left">
                    <p className="desginObjectiveHeader">Improving Efficiency</p>
                      <span className="designObjective_header">
                        {/* <div className="designObjective-icon-efficiency"/> */}
                        <h3>3. Reduce Cognitive Load</h3>
                      </span>
                      <p>By displaying only the top 10 matching results, we would reduce the cognitive load on an already anxious security analyst.</p>
                    </div>
                    <div className="sectionSplit_right">
                    <div className="img-tenItems"/>
                    </div>
                  </section>
              </div>

              <div className="sectionContainer">
                <section className="sectionSplit">
                    <div className="sectionSplit_left">
                      <div className="img-previousSearches"/>
                    </div>
                    <div className="sectionSplit_right">
                    <p className="desginObjectiveHeader">Improving Efficiency</p>
                      <span className="designObjective_header">
                        {/* <div className="designObjective-icon-efficiency"/> */}
                        <h3>4. Display Previously Searched Queries</h3>
                      </span>
                      <p>Users wanted to re-run previously run searches and can do so in <b>2 clicks</b> (Recognition is better than recall)</p>
                    </div>
                  </section>
              </div>

              <div className="sectionContainer">
                <section className="sectionSplit">
                    <div className="sectionSplit_left">
                    <p className="desginObjectiveHeader">Improving Discoverability</p>
                      <span className="designObjective_header">
                        {/* <div className="designObjective-icon-discoverability"/> */}
                        <h3>5. Discoverability of Filters</h3>
                      </span>
                      <p>By displaying only the top 10 matching results, we would reduce the cognitive load on an already anxious security analyst.</p>
                    </div>
                    <div className="sectionSplit_right">
                    <div className="img-filterDiscoverability"/>
                    </div>
                  </section>
              </div>
            </div>

            <div className="sectionbg-alt">
              <div className="sectionContainer">
                <section className="sectionCentre">
                <h2>Interesting user behavior while testing</h2>
                <p>When conducting usability tests with <b>6 users</b> involving <b>5 user tasks with a prototype</b> I built with typeahead.js and bloodhound.js, we observed that users assumed they couldn’t search using certain filters as it didn’t show up as a suggestion.</p>
                <h2>Modified design</h2>
                <div className="img-thefix"></div>
                </section>
              </div>
            </div>

            <div className="sectionbg">
              <div className="sectionContainer">
                <section className="sectionCentre">
                <h2>Result</h2>
                <p>
                On average(when measured over a week), we saw a <b>12% daily engagement rate</b>.
                </p>
                <p>
                  <em>"I love the advanced search functionality (auto complete, suggestions, everything). You did it right, it is intuitive, it is better than [a well known product] and you should copyright it and sell it to others"</em>
                  <br></br>
                  -a very happy user
                </p>
                
                </section>
              </div>
            </div>

          </div>    
        );
      }
    }
export default Autocomplete;