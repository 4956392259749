import React, { Component } from 'react';
import Intro from './Intro';
import ProductWork from './ProductWork';
import Dribbble from './Dribbble';

class Home extends Component {
    render() {
    return (
        <div class='body'>
            <Intro/>
            <Dribbble/>
            <ProductWork/>
        </div>
    );
  }
}

export default Home;