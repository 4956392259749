import React from "react";
import { Link } from 'react-router-dom'

const Error = () => {
    return(
        <div className="Error">
            <br/>
            <p>The page you are looking for cannot be found.</p>
            <Link to='/'>
                <p>Go back home</p>
            </Link>
        </div>
    );
}
export default Error;