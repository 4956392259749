import React, { Component } from 'react';
import acceptTask from '../img/interactions/acceptTask2.gif';
import selectHighlight from '../img/interactions/selectHighlight.gif';
import getAway_lucky from '../img/interactions/getAway_luckySmall.gif';
import series from '../img/interactions/series.gif';
import emptyState from '../img/interactions/emptyState@2x.png';
import name from '../img/interactions/name.gif';
import legend from '../img/interactions/legend.gif';
import puzzledError from '../img/interactions/puzzled_error@2x.png';
import '../css/dribbble.css'

class Dribbble extends Component {
	render() {
		return (
			<div class="interactionContainer">
				<h1>Interactions</h1>
				<div className="dribbbleParent">
				
					<div class="item2">
						{/* <a href="https://dribbble.com/shots/2930328-Task-accepted" target="_blank"> */}
			 				<div className="interactions">
			 				<img src={acceptTask} alt="acceptTask2"/>
			 				</div>
			 			{/* </a> */}
					</div>
					<div class="item3">
						{/* <a href="https://dribbble.com/shots/2930257-List-item-selection-interaction" target="_blank"> */}
			 				<div className="interactions">
			 					<img src={selectHighlight} alt="selectHighlight"/>
			 				</div>
			 			{/* </a> */}
					</div>
					<div class="item6">
						{/* <a href="https://dribbble.com/shots/4663259-Empty-state-illustration" target="_blank"> */}
			 				<div className="interactions" id="interactionName">
			 					<img src={name} id="name" alt="emptyState"/>
			 				</div>
			 			{/* </a> */}
					</div>
					<div class="item5">
						{/* <a href="https://dribbble.com/shots/2930302-Wireframe-interactions" target="_blank"> */}
			 				<div className="interactions">
			 					<img src={series} id="wireframe_interaction" alt="series"/>
			 				</div>
			 			{/* </a> */}
					</div>
					
					<div class="item5">
						{/* <a href="https://dribbble.com/shots/4663219-Error-illustration" target="_blank"> */}
			 				<div className="interactions">
			 					<img src={legend} id="legend" alt="legend"/>
			 				</div>
			 			{/* </a> */}
					</div>
				</div>
                <a href="https://dribbble.com/mvishnu" target="_blank" class="link">
					<p class="link">See all</p>
				</a>

			</div>
			
		);
	}
}

export default Dribbble;