import React, { Component } from 'react';
import linkedinIcon from '../img/icons/linkedin_aaa.svg';
import gitHubIcon from '../img/icons/github_aaa.svg';
import dribbbleIcon from '../img/icons/dribbble_aaa.svg';
import '../css/social.css'

class SocialWhite extends Component {
    render() {
        return(
            <div className="social">
                <a href="https://in.linkedin.com/in/vishnukumaar" target="_blank">
                    <img src={linkedinIcon} id="linkedinImage" className="socialIcons"/>
                </a>
                <a href="https://github.com/vishnukumaar" target="_blank">
                    <img src={gitHubIcon} id="githubImage" className="socialIcons"/>
                </a>
                <a href="https://dribbble.com/mvishnu" target="_blank">
                    <img src={dribbbleIcon} id="dribbbleImage" className="socialIcons"/>
                </a>
            </div>
        );
    }
}
export default SocialWhite;