import React, { Component } from 'react';
import '../css/products.css'
import { Link } from 'react-router-dom'
import wifi from '../files/proj_1.pdf';
import {NavLink} from 'react-router-dom';

class ProductWork extends Component {
    render() {
    return (
        <div class="productContainer">
            <h1>Product Work</h1>

            <section className="productCentre">
                    <div class="product" id="product-pricing">
                        <div class="productText" id="productText-pricing">
                            <h2>Pricing page</h2>
                            <p> How might we improve conversion on a pricing page</p>
                            <p>
                                <a target='_blank' href='https://www.figma.com/proto/ZcRkBmKogWmrULCXwUpC2g/Portfolio---Pricing-page?page-id=3%3A13878&node-id=3%3A13897&viewport=241%2C48%2C0.03&scaling=min-zoom' target="_blank">Learn more</a>
                            </p>
                        </div>
                        <div className="img-pricing"></div>
                    </div>
            </section>

            <section className="productCentre">
                    <div class="product" id="product-wifi">
                        <div className="img-wifi"></div>
                        <div class="productText" id="productText-wifi">
                            <h2>In search of WiFi</h2>
                            <p>Designed an experience to help people find Wifi hotspots</p>
                            <p>
                                <a target='_blank' href='https://www.figma.com/proto/3G42EkAdY202RUqDyHajRt/Portfolio---In-search-of-WiFi?page-id=0%3A1&node-id=1%3A344&viewport=241%2C48%2C0.45&scaling=min-zoom' target="_blank">Learn more</a>
                            </p>
                        </div>
                    </div>
            </section>

            <section className="productCentre">
                    <div class="product" id="product-autocomplete">
                        <div class="productText">
                            <h2>Autocomplete Suggestions</h2>
                            <p>Designed an enhanced search experience for cyber security analysts</p>
                            {/* <p>Designed a <b>language based query builder for senior security analysts</b> so that they <b>don't abandon our product</b> and use other products to search for security threats</p> */}
                            <p>
                                <a target='_blank' href='https://www.figma.com/proto/pcnzhPEyfuIZQ8c5aso6FS/Autocomplete-suggestions?node-id=1%3A46&viewport=307%2C2234%2C0.18243972957134247&scaling=min-zoom' target="_blank">Learn more</a>
                            </p>
                        </div>
                        <div className="img-autocomplete" id="productimg-autocomplete"></div>
                    </div>
            </section>
               
            <section className="productCentre">
                
                    <div class="product" id="product-tictactoe">
                        <div className="img-tictactoe"></div>
                        <div class="productText" id="productText-tictactoe">
                            <h2>Tic Tac Toe</h2>
                            <p>Created the classic game on Android and iOS using react native</p>
                            <p>
                            <Link to="/Tictactoe">Learn more</Link>
                            </p>
                        </div>
                    </div>
            </section>
        </div>
    );
  }
}

export default ProductWork;