import React, { Component } from 'react';
import '../css/header.css'
// import resume from '../files/resume_aug_2019.pdf';
import {NavLink} from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';

class Header extends Component {
    render() {
      return (
        <div className="sticky-nav">
            {/* <NavLink to="/" id="nav-myname">VISHNU</NavLink>
            {/* <button id='nav-myname'></button> */}
            {/* <NavLink to="#projectsSection" id="nav-work">Work</NavLink> */}
            {/* <NavLink to={resume} target="_blank" id="nav-resume">Resume</NavLink> */} 

            <Link to="/" id="nav-myname">VISHNU</Link>
            {/* <button id='nav-myname'></button> */}
            <Link to="/#projectsSection" id="nav-work">Work</Link>
            {/* <Link to={resume} target="_blank" id="nav-resume">Resume</Link> */}
            <a href="https://docs.google.com/presentation/d/1ch8unbKPtMrI5CGgSNGKdC-gwCflr1kr0m7mYa35IZ0/edit?usp=sharing" target="_blank" id="nav-presentation">Slides</a>
        </div>
        );
    }
  }
  
export default Header;