import React from 'react';
import './App.css';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import Error from './components/Error';
import Header from './components/Header';
import Autocomplete from './components/products/Autocomplete';
import Tictactoe from './components/products/Tictactoe';
import Footer from './components/Footer';
import Home from './components/Home';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        {/* <Header/> */}
        <Switch>
          <Route path='/' component={Home} exact/>
          <Route path='/Autocomplete' component={Autocomplete} exact/>
          {/* <Route path='/styleGuide' component={styleGuide} exact/> */}
          <Route path='/Tictactoe' component={Tictactoe} exact/>
          
          <Route component={Error} />
        </Switch>
        <Footer/>
      </div>
      </BrowserRouter>
  );
}

export default App;
