import React, { Component } from 'react';
import '../css/intro.css'
import '../css/intro.css'
import Social from './SocialColor';
import {Link} from 'react-router-dom'
import resume from '../files/resume.pdf';
import {NavLink} from 'react-router-dom';

class Intro extends Component {
    render() {
      return (
        <div className="intro_container">
            <div className="intro_content">
                <div className="img-myBrand"></div>
                <h1>வணக்கம், hi, bonjour!</h1>
                <p id="intro_para">
                I’m <b>Vishnu</b>, a Product Designer based in the San Francisco Bay Area.<br></br> 
                I am currently designing solutions that impact millions of people everyday.<br></br> 
                Always looking to collaborate. Have an interesting idea in mind, hit me up!
                </p>
                <p><NavLink to={resume} target="_blank">View resume</NavLink></p>
                <p><Social/></p>
            </div>
            </div>
        );
    }
  }
  
  export default Intro;