
import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import back from '../../img/icons/back.svg'
import '../../css/products/tictactoe.css'

class Tictactoe extends Component {
    componentDidMount() {
      window.scrollTo(0, 0);
    }
    render() {
      document.title ="TicTacToe | Vishnu"; 
      return (
        <div class="individualProductContainer">
          <div className="sectionContainer" >
            <section className="sectionSplit" id="header-tictactoe"> 
              <div className="sectionSplit_left">
                <Link to="/" >
                <div className="backButton">
                  <img src={back} alt="back"/>
                  <h3>&nbsp;&nbsp;Back</h3>
                  </div>
                </Link>
                <h1>Tic Tac Toe Built in React Native</h1>
                
                <div className="productMeta"> 
                  <p>I was fascinated with the idea of being able to make quick prototypes for Android and iOS using React Native. Hence I decided to create the classic game on Android and iOS.</p>
                  <p>
                    <a target='_blank' href='https://play.google.com/store/apps/details?id=com.tictactoe4'>View in Play Store</a>
                  </p>
                </div>
              </div>

              <div className="sectionSplit_right">
                <div className="img-hero-tictactoe"></div>
              </div>
            </section>

            <div className="sectionContainer">
              <section className="sectionCentre">
                <h2>App screenshots</h2>
                <div className="img-set1"></div>
                <div className="img-set2"></div>
              </section>
            </div>
          </div>
        </div>
        );
      }
    }
export default Tictactoe;